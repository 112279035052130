@import "src/app/styles/fonts";
@import "src/app/styles/mixinScroll";

.list-card-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-radius: 1rem;
}