@import "src/app/styles/fonts";


.SendForm {
  width: 100%;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7rem;
  height: 7rem;
  background: rgb(18 18 18 / 44%);
  border-radius: 1rem;
}