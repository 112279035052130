.button {
  text-transform: none;
}

.active {
  background: #DBDBDB;
  border: 1px solid rgb(130 133 133 / 0%);
  border-radius: 0.5rem;
  color: #1D1D1D;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.5s;
  width: 5.58rem;
}

.no-active {
  background: #1D1D1D;
  border: 1px solid #3d3d3d;
  border-radius: 0.5rem;
  color: #828585;
  font-size: 14px;
  font-weight: 600;
  width: 5.58rem;
}