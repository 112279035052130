@import "src/app/styles/fonts";

.container {
  position: relative;
}

.fullWidth {
  width: 100%;
}

.label {
  @include BodyS-600;
  color: var(--main-white);
}

.startIcon,
.endIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  svg {
    fill: var(--g-200);
  }
}

.startIcon {
  left: 1rem;
}

.endIcon {
  right: 1rem;
}

.errorText {
  @include BodyS-600;
  fill: var(--error-500);
  color: var(--error-500);
}

.error {
  border: 1px solid var(--error-500) !important;
  word-break: break-all;
}

.input {
  border-radius: 0.5rem;
  @include BodyS-600;
  padding: 1rem;
  border: 1px solid var(--g-600);
  background: transparent;
  fill: var(--g-200);
  color: var(--g-50);
  resize: none;

  &:focus-visible {
    outline-offset: unset;
    border: 1px solid transparent;
    outline: 2px solid var(--g-50);
  }

  &::placeholder {
    @include BodyS-600;
    color: var(--g-500);
  }

  &:hover {
    background: var(--g-700);
  }

  &:disabled {
    background-color: var(--g-800);
    color: var(--g-500);
    border: 1px solid transparent;
  }
}