@import "src/app/styles/fonts";

.new-shipment {

  &__tracking-details {
    padding: 0 2rem 2rem;
    display: flex;
    justify-content: space-between;

    &__left {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: 2.5rem;
      padding: 2.5rem 0 1.25rem;
      min-height: 13.75rem;
      width: 100%;

      h3 {
        @include BodyM-XS-600;

        color: var(--o-400);
        width: 51%;
      }

      &__form {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 1rem;

        form {
          display: flex;
          gap: 1rem;
          align-items: center;
        }
      }
    }

    &__right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      min-width: 11.5rem;

      &__buttons {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
      }

      &__prints {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }
    }
  }
}