.menuTopLeft {
  bottom: 100%;
  right: 0;
  width: 100%;
}

.menuTopRight {
  bottom: 100%;
  left: 0;
  width: 100%;
}

.menuBottomLeft {
  top: 100%;
  right: 0;
  width: 100%;
}

.menuBottomRight {
  top: 100%;
  left: 0;
  width: 100%;
}