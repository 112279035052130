@import "src/app/styles/fonts";
@import "src/app/styles/mixinScroll";

.cancel-offer-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 2.5rem 0;
  border-radius: 1rem;
  width: 37.5rem;
  min-height: 20rem;
  position: relative;
}