@import "src/app/styles/fonts";
@import "src/app/styles/mixinScroll";

.list-card-modal-success {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 37.5rem;
  gap: 2.8125rem;
  background-color: var(--g-900);
  border-radius: 1rem;
  padding: 2.5rem;
  position: relative;

  &__close-btn {
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__asking-price {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 0.625rem;

      &__text {
        @include BodyM-600;

        color: var(--g-300);
      }

      &__currency {
        display: flex;
        align-items: center;
        gap: 0.25rem;

        @include BodyM-600;

        color: var(--main-white);
      }
    }

    &__transaction {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 0.375rem;

      &__title,
      &__description a {
        @include BodyM-600;
      }

      &__title {
        color: var(--g-300);
      }

      &__description  {
        
        a {
          color: var(--b-400);
          text-decoration: none;
          gap: 0.5rem;
        }
      }
    }
  }
}