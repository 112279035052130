@import "src/app/styles/fonts";
@import "src/app/styles/mixinScroll";

.full-info {
  background-color: var(--g-900);
  border-radius: 1rem;
  padding: 2.063rem;

  h3 {
    @include H5-CL;
    color: var(--main-white);
  }

  h5 {
    @include BodyS-400;
    color: var(--g-300);
    text-align: center;
    max-width: 38.5rem;
  }

  .details {
    margin-top: 1rem;

    .right {
      background-color: var(--g-700);
      border-radius: 1rem;

      .main {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        width: 100%;
        padding: 1.5rem;
        border-bottom: 1px solid var(--g-900);

        .title {
          @include H5;
          color: var(--main-white);
        }

        .title+div {
          margin-top: 0.75rem;
        }

        .owner-price {
          color: var(--g-300);
          @include BodyS-600;

          a {
            color: var(--b-400);
            cursor: pointer;
          }

          span {
            color: var(--main-white);
          }
        }
      }

      .more {
        padding: 1.5rem;
        height: 100%;

        .alert {
          padding-top: 1rem !important;
        }

        &>div:last-child {
          margin-top: auto;
        }

        .token-id {
          @include BodyS-600;
          padding-top: 0 !important;

          &>p {
            color: var(--g-300);
          }

          &>p+p,
          a {
            color: var(--b-400);
          }

          a {
            cursor: pointer;
          }

          .white {
            color: var(--main-white);
          }
        }
      }
    }
  }

  .horizontal {
    flex-direction: row !important;
    justify-content: space-between;
  }
}

.loader {
  height: 34.188rem;
}