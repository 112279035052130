@import "src/app/styles/fonts";

.header {
  padding: 1.25rem 2.5rem;
  background-color: var(--g-700);
  border-radius: 1rem;

  svg {
    cursor: pointer;
  }
}