.RadioButton {
  display: flex;
  align-items: center;
  margin-right: 4px;
}

.radioLabel {
  color: #303030;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
}

.radioInput {
  margin: -9px;
  visibility: hidden;
}

.radioInput:checked+span {
  border: 1px solid var(--o-400);
}

.radioInput:checked+span::after {
  opacity: 1;
}

.customRadio {
  left: 0.375rem;
  top: 0.1875rem;
  cursor: pointer;
  width: 1rem;
  height: 1rem;
  border: 1px solid #9a9a9a;
  border-radius: 50%;
  display: inline-block;
  position: relative;
}

.customRadio::after {
  content: '';
  width: 0.625rem;
  height: 0.625rem;
  background: var(--o-400);
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-52%, -51%);
  opacity: 0;
  transition: opacity 0.2s;
}