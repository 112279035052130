@import "src/app/styles/fonts";
@import "src/app/styles/mixinScroll";

.cancel-modal {
  width: 40rem;
  min-height: 17rem;

  .close {
    min-height: 4rem;
  }

  h1 {
    @include H2;

    color: var(--main-white);
    text-transform: uppercase;
  }

  .main-content {
    color: var(--main-white);
    height: 100%;

    @include BodyM-400;

    .buttons {
      padding: 0 0.5rem;
    }
  }
}