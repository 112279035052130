@import "src/app/styles/fonts";
@import "src/app/styles/mixinScroll";

.loading-screen-cancelation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--g-900);
  border-radius: 1rem;
  gap: 1.5rem;

  &__description {
    @include BodyM-XS-600;

    color: var(--g-200);
    text-align: center;
  }
}