@import "src/app/styles/fonts";
@import "src/app/styles/mixinScroll";

.SuccessInvited {
  display: flex;
  flex-direction: column;
  margin: 6.4375rem 0;
}

.ordinaryText {
  padding-top: 0.5rem;
}

.button {
  width: 100%;
  padding-top: 3.25rem;
}
