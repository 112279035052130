@import "src/app/styles/fonts";
@import "src/app/styles/mixinScroll";

.SealedBoxUpdateListingSuccess {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.description {
  display: flex;
  align-items: center;
  gap: 0.375rem;

  @include BodyS-600;

  color: var(--g-300);

  svg {
    cursor: pointer;
  }
}

a {
  text-decoration: none
}

.link {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.linkValue {
  color: var(--b-400);
}