@import "src/app/styles/fonts";
@import "src/app/styles/mixinScroll";

.ListUpdateSealedBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  border-radius: 1rem;
}