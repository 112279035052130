@import "src/app/styles/fonts";

.email-modal {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  background: var(--g-900);
  height: fit-content;
  width: 40rem;
  padding: 2.5rem;
  border-radius: 1.125rem;

  h2 {
    @include H2;
    text-transform: uppercase;
    color: var(--main-white);
  }

  .content {
    color: var(--main-white);
    @include BodyS-600;

    &>p:not(p+p) {
      @include BodyM-400;
      color: var(--g-300);
      margin: 1rem 0 2rem;
    }

    p+p {
      color: var(--main-white);
      margin: 0.5rem 0;
    }

    .buttons {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      margin-top: 2rem;
    }
  }
}