.overlay {
  background: rgb(0 0 0 / 80%);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  inset: 0;
  z-index: var(--overlay-z-index);
  cursor: pointer;
}

.blur {
  backdrop-filter: blur(2px);
}