@import "src/app/styles/fonts";
@import "src/app/styles/mixinScroll";

.ListSealedBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  border-radius: 1rem;
  width: 600px;
  min-height: 568px;
  position: relative;
}