@import "src/app/styles/fonts";

.transfer-modal-cards {
  margin: auto;
  width: 37.5rem;
  min-height: 21.5rem;

  &__content {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-direction: column;
    gap: 0.5rem;
    background: var(--g-900);
    border-radius: 1rem;

    &__header {
      display: flex;
      justify-content: flex-end;
      padding: 1.375rem 1.375rem 0 0;
      background: var(--g-900);
      align-items: center;
      border-radius: 1rem;
    }

    &__main-content {
      display: flex;
      padding: 0 2.5rem 3rem;

      &__success-fail {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        color: var(--g-200);
        width: 100%;

        & > svg {
          margin-bottom: 1rem;
        }

        & > h3 {
          @include H3;

          color: var(--main-white);
        }

        & > p {
          @include BodyM-400;
        }

        &__buttons {
          display: flex;
          gap: 1.5rem;
          margin-top: 2rem;
        }

        &__partly {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          padding: 1rem 1.5rem;
          border: 1px solid var(--g-600);
          border-radius: 1rem;
          width: 24.8rem;
          margin-top: 1.5rem;

          & > div {
            display: flex;
            justify-content: space-between;
            padding: 0.25rem 0;

            & > div {
              display: flex;
              gap: 0.5rem;
              align-items: center;
            }
          }

          span {
            color: var(--main-white);
          }
        }
      }
    }

    &__loading {
      height: 19.25rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2.25rem;

      @include BodyM-600;

      color: var(--g-200);
    }
  }
}

.transfer-modal-cards:focus-visible {
  outline: none;
}