@import "src/app/styles/fonts";

.SuccessSent {
  padding: 2.5rem;
  width: 100%;
}

.title {
 @include H4-CL;
}

.greyColor{
  color: var(--g-300);
}

.info {
  @include BodyS-600;
  text-align: center;
}

.link {
  @include BodyS-600;
  color: var(--b-400);
}