/* stylelint-disable selector-id-pattern */
@import "src/app/styles/fonts";


.Form {
  width: 100%;
  padding: 0 0.5rem;
}

.input {
  padding: 0.75rem;
}

.Available {
  @include BodyM-XS-600;

  color: var(--g-200)
}

.count {
  @include BodyM-XS-600;

  color: var(--o-400);
}

.btn {

  &:disabled {
    background-color: var(--g-900) !important;
  }
}

.InputIcon {
  display: flex;
  gap: 4px;
  padding: 4px 8px;
  min-width: 63px;
  height: 28px;
  background: #0D0D0D;
  border-radius: 8px;
}

.inputEndIcon {
  
  position: absolute;
  right: 8px;
  top:  23px;
  width: auto;
  height: 28px;
}

.name {
   @include BodyM-XS-600;
}

.sol {
  width: 16px !important;
  height: 16px !important;
  fill: none !important;
  background: linear-gradient(150.69deg, #2BE4CA 13.99%, #1BCBB8 20.5%, #4D78E4 53.32%, #8B61FC 75.3%, #AD8FFF 89.01%);
  border-radius: 100px;
}

.errorMessage {
  @include BodyM-XS-600;
}

.inputValue {
  position: relative;
}

.availableMount {
  position: absolute;
  top: 0;
  right: 0;
}