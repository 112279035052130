@import "src/app/styles/fonts";

.label {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.input-select {
  text-wrap: nowrap;
}

.placeholder {
  color: var(--g-500);
}

.small {
  padding: 0.5rem 0.75rem !important;
}