@import "src/app/styles/fonts";

.main-header-wrapper {
  z-index: 1000;
  max-width: 1424px;
  background-color: var(--main-black);
  padding: 0.5rem 0 1rem;
  position: fixed;
  top: 0;

  .content {
    display: flex;
    justify-content: space-between;
    position: relative;
    background: var(--g-700);
    border-radius: 1rem;
    padding: 1.125rem 0 1.125rem 2rem;

    .logo {
      background-color: var(--g-700);
      border-radius: 1rem;
      padding-top: 0.15rem;
      cursor: pointer;
    }

    .icons {
      background-color: var(--g-700);
      border-radius: 1rem;

      p {
        font-weight: 600 !important;
        font-size: 1rem !important;
        text-transform: none !important;
      }

      .border {
        width: 1px;
        background-color: var(--g-600);
        height: 1.5rem;
      }
    }

    .right-block {
      padding-right: 2rem;

      .profile {
        background-color: var(--g-700);
        border-radius: 1rem;

        .notifications {
          cursor: pointer;
          padding: 6px 9px 7px;
          border-radius: 50%;

          &:hover {
            background-color: var(--g-600);
          }

          &:active {
            background-color: var(--g-900);
          }
        }

        .with-photo {
          display: flex;

          &:hover {
            opacity: 0.4;
          }

          &:active {
            opacity: 0.3;
          }

          img {
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 50%;
            cursor: pointer;
            object-fit: cover;
          }
        }

        .wrapper-avatar {
          cursor: pointer;
        }

        .user {
          cursor: pointer;

          svg {
            fill: none;

            path {
              fill: #828585;
            }

            &:hover {
              fill: none;

              path {
                fill: #939393;
              }

              rect: {
                fill: white;
              }

              rect:first-child {
                fill: #3d3d3d;
              }
            }

            &:active {

              path {
                fill: #828585;
              }

              rect {
                fill: #1d1d1d;
              }
            }
          }
        }

        .burn-cart {
          padding-right: 1rem;
          width: 5.063rem;
          cursor: pointer;

          &:hover {
            border-radius: 3.5rem;
            background-color: var(--g-600);
          }

          &:active {
            background-color: var(--g-900);
            transition: 0.3s;
            opacity: 0.5;
          }

          .circle {
            fill: transparent;
            stroke: var(--g-200);
            padding: 0.5rem 0.625rem;
            background-color: var(--g-600);
            border-radius: 50%;
            display: flex;
            align-self: center;
            margin-left: 0.25rem;
          }

          .active-circle {
            fill: var(--o-500);
            stroke: var(--o-500);
            background-color: white;
          }

          .count {
            color: var(--g-400);
            @include BodyS-600;
          }

          .active-count {
            color: white;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .main-header-wrapper {
    position: initial;
    width: min-content;
    padding-bottom: 0;

    .content {
      width: 100%;
      flex-wrap: wrap;
      padding-left: 1rem;
      justify-content: center;
    }

    .right-block {
      padding-right: 0;
      padding-top: 1rem;
    }
  }
}