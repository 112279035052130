.modal {
  position: fixed;
  inset: 0;
  opacity: 0;
  pointer-events: none;
  z-index: 100;
  display: flex;
  align-items: center;
}

.content {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0.5rem;
  background: var(--g-900);
  padding: 2rem 2rem 2.5rem;
  border-radius: 1rem;
  z-index: var(--modal-z-index);
}

.opened {
  pointer-events: auto;
  opacity: 1;
  z-index: var(--modal-z-index);
}

.left {
  justify-content: flex-start;
}

.center {
  justify-content: center;
}

.right {
  justify-content: flex-end;
}

.no-overlay {
  background: inherit !important;
}