@import "src/app/styles/fonts";


.SendQRCode {
  width: 100%;
}

.icons {
  cursor: pointer;
}

.title {
  @include H6-CL;

}

.QRCode {
  justify-content: center;
  margin-bottom: 12px;

  &>canvas{
    width: 160px;
    height: 160px;
    background: none;
  }
}

.walletInfo {
  padding: 12px 20px 12px 12px;
  background: rgb(18 18 18 / 44%);
  border-radius: 16px;
}

.address {
  @include BodyM-XS-600;
}

.solana {
  @include BodyM-XS-600;

  color: var(--g-300);
}

.copyText {
  cursor: pointer;
}

.addressWrapper {
  max-width: 100%;
  text-align: center;
  word-break: break-all;
  padding: 0.5rem 0;
}

.walletName {
  @include BodyM-XS-400;
  color: var(--g-300)
}

.info {
  padding: 0.75rem 1rem;
  background: rgb(59 99 139 / 20%);
  border-radius: 12px;
  min-height: 6rem;
}

.description {
  @include BodyM-XS-400;

  color: var(--info-700);

  span {
    color: var(--main-white);
  }
}