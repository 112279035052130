@import "src/app/styles/fonts";

.user-activity-modal {
  position: fixed;
  transform: translateX(70%);
  top: 91px;
  background-color: var(--g-900);
  height: 100%;
  max-height: 655px;
  width: 421px;
  border-radius: 1rem;
  box-shadow: 0 24px 48px 16px rgb(0 0 0 / 28%),
    0 8px 20px 0 rgb(0 0 0 / 12%);

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: var(--g-700);
    padding: 1rem;
    border-radius: 1rem 1rem 0 0;

    &__title,
    &__text-button {
      color: var(--main-white);
    }

    &__title {
      @include H5;
    }

    &__text-button {
      @include BodyM-XS-600;

      text-transform: uppercase;
      color: white;
      cursor: pointer;
    }
  }

  &__content {
    padding: 1rem;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 590px;
    height: 100%;

    &__requires-header {
      display: flex;
      flex-direction: row;
      gap: 0.25rem;

      &__text {
        @include BodyM-XS-600;

        color: var(--g-300);
        text-transform: uppercase;
      }
    }

    &__empty {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      color: var(--g-300);
    }
  }
}