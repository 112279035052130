@import "src/app/styles/fonts";

.IncDecButtonsWrapper {
  border: 1px solid var(--g-500);
  width: 11.938rem;
  height: 3rem;
  padding: 0.5rem;
  border-radius: 0.375rem;
}

.buttonItem {
  background: var(--g-800);
  fill: var(--g-500);
  padding: 0.25rem;
  height: 2rem;
  width: 2rem;
}