@import "src/app/styles/fonts";

.summary-black {
  padding: 1rem;
  border-radius: 1rem;
  border: 1px solid #282828;

  .data-summary-title {
    fill: #ff7c01 !important;
    color: #121212 !important;
    text-transform: none;
  }

  .data-title {
    color: var(--g-400) !important;
    @include BodyM-XS-600;
  }

  .data-value {
    @include BodyM-XS-600;
  }

  .data-type {
    color: var(--g-800) !important;
  }

  .title {
    @include BodyM-600;
    color: var(--g-100);
    fill: var(--g-100);

    &>div {
      padding-bottom: 0.5rem;
    }
  }

  .details {
    margin: 0;

    &>div {
      @include BodyS-400;

      :nth-child(odd) {
        color: var(--g-300);
      }

      :nth-child(even) {
        color: var(--main-white);
      }
    }
  }

  .total {
    text-transform: uppercase;
    @include BodyM-600;
    letter-spacing: -0.01em;
    color: var(--o-400);
  }

  .data-type-total {
    @include BodyS-600;
  }
}

.summary-light {
  border: 1px solid #DBDBDB !important;
}