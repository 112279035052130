@import "src/app/styles/fonts";

.afterIcon {
  flex-direction: row !important;
  align-items: center !important;
  gap: 1rem !important;
}

.paddingLeft {
  padding-left: 2.75rem;
}

.paddingRight {
  padding-right: 2.75rem;
}

input[type="search"]::-ms-clear {
  display: none;
}

input[type="search"]::-webkit-search-cancel-button {
  display: none;
}


input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s !important;
}