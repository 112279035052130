@import "src/app/styles/fonts";
@import "src/app/styles/mixinScroll";

.empty-info {
  background-color: var(--g-900);
  border-radius: 1rem;
  height: 34.188rem;

  h3 {
    @include H4;
    color: var(--g-100);
    padding-top: 1rem;
  }

  .ordinary-text {
    padding-top: 0.25rem;
    text-align: center;

    p {
      @include BodyS-400;
      color: var(--g-200);
    }
  }

  .empty-state {
    @include BodyS-400;
    color: var(--g-200);
  }
}

.loader {
  height: 34.188rem;
}