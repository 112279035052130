@import "src/app/styles/fonts";

.BuyNowHovered {
  width: 100%;
  height: 58px;
  display: flex;
  background-color: white;
  bottom: 0;
  left: 0;
  border-radius: 0 0 1rem 1rem !important;
  color: var(--main-black);
  position: absolute;

  &:hover {
    background-color: var(--g-100);
  }

  &:active {
    background-color: var(--g-200);

    .insurance {
      border-left: 1px solid var(--g-300);
    }
  }
}

.insurance {
  @include BodyM-XS-600;

  padding: 0 1rem;
  white-space: nowrap;
  border-left: 1px solid var(--g-50);
}

.btn {
  @include B-Small;

  width: 100%;
}