@import "src/app/styles/fonts";
@import "src/app/styles/mixinScroll";

.error-block {
  display: flex;
  justify-content: center;

  &__content {
    display: flex;
    flex-direction: column;

    @include BodyM-XS-600;

    color: var(--error-500);

    &__icon {
      display: flex;
      flex-direction: row;
      align-self: center;
      align-items: center;
      gap: 0.188rem;
    }
  }
}