/* stylelint-disable scss/at-mixin-pattern */
// Heading
@mixin H1 {
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.5rem;
}

@mixin H1-CL {
  font-family: clash-display, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 4rem;
  line-height: 5rem;
  text-transform: uppercase;
}

@mixin H2 {
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.75rem;
  line-height: 2rem;
}

@mixin H2-CL {
  font-family: clash-display, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 3.5rem;
  line-height: 4rem;
  text-transform: uppercase;
}

@mixin H3 {
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.75rem;
}

@mixin H3-CL {
  font-family: clash-display, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 2.5rem;
  line-height: 3rem;
  text-transform: uppercase;
}

@mixin H4 {
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

@mixin H4-CL {
  font-family: clash-display, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.75rem;
  line-height: 2.5rem;
}

@mixin H5 {
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
}

@mixin H5-CL {
  font-family: clash-display, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2.25rem;
}

@mixin H6-CL {
  font-family: clash-display, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 2rem;
}

// Paragraph
@mixin BodyL-400 {
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

@mixin BodyL-600 {
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

@mixin BodyM-400 {
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
}

@mixin BodyM-600 {
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
}

@mixin BodyS-400 {
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.85rem;
  line-height: 1.25rem;
}

@mixin BodyS-600 {
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.85rem;
  line-height: 1.25rem;
}

@mixin BodyM-XS-400 {
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.125rem;
}

@mixin BodyM-XS-600 {
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.125rem;
}

@mixin Body-Tiny-800 {
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 0.625rem;
  line-height: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.08em;
}

// Button
@mixin B-Large {
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
  text-transform: uppercase;
}

@mixin B-Medium {
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  text-transform: uppercase;
}

@mixin B-Small {
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.85rem;
  line-height: 1rem;
  text-transform: uppercase;
}

@mixin Btn-S-Up {
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  text-transform: uppercase;
}

// Heading - "clash-display"
@mixin W-H1-CL {
  font-family: clash-display, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 4.5rem;
  line-height: 5rem;
}

@mixin W-H2-CL {
  font-family: clash-display, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 3.5rem;
  line-height: 4rem;
}

@mixin W-H3-CL {
  font-family: clash-display, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 3rem;
  line-height: 3.5rem;
}

@mixin W-H4-CL {
  font-family: clash-display, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.5rem;
}

@mixin W-H5-CL {
  font-family: clash-display, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2.25rem;
}

@mixin W-H6-CL {
  font-family: clash-display, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 2rem;
}

@mixin W-H7-CL {
  font-family: clash-display, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
}

@mixin W-H8-CL-UP {
  font-family: clash-display, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5rem;
  text-transform: uppercase;
}

// Heading - Manrope

@mixin W-H1 {
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 3rem;
}

@mixin W-H2 {
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 2rem;
}

@mixin W-H3 {
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
}

@mixin W-H4 {
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

// Body - Manrope

@mixin W-Body-XXL {
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 2rem;
}

@mixin W-Body-XXL-Bold {
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 2rem;
}

@mixin W-Body-L {
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
}

@mixin W-Body-L-Bold {
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
}

@mixin W-Body-M {
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

@mixin W-Body-M-Bold {
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

@mixin W-Body-S-Bold-UP {
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1.2rem;
  text-transform: uppercase;
}

@mixin W-Body-XS {
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.6rem;
  line-height: 1rem;
}

@mixin W-Body-XS-UP {
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.6rem;
  line-height: 1rem;
  text-transform: uppercase;
}

// Special

@mixin W-BTN-MEDIUM {
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-transform: uppercase;
}

// market place switcher

@mixin Product-type-Name {
  font-family: Manrope, sans-serif;
  font-weight: 800;
  font-size: 0.625rem;
  line-height: 1rem;
  letter-spacing: 0.3em;
  text-transform: uppercase;
}