@import "src/app/styles/fonts";

.cardCosts {
  display: flex;
  gap: 1.5rem;
  color: var(--main-black);
  border-radius: 0.75rem;
  height: fit-content;

  .line {
    border: 1px dashed var(--main-black-40);
    width: 100%;
  }

  p {
    @include BodyM-XS-400;
  }

  h5 {
    @include H5-CL;
    z-index: 1;
  }

  h3 {
    @include Body-Tiny-800;
    opacity: 0.8;
  }

  .block {
    padding: 0.5rem 0.75rem;
    border-radius: 0.75rem;
    min-width: 9.5rem;
    position: relative;
    width: 100%;

    .top {
      width: 100%;

      span {
        @include BodyM-XS-400;
        margin-top: 0.25rem;
      }
    }

    p>p>span {
      @include BodyM-XS-600;
    }
  }

  svg {
    fill: var(--main-black-80);

    circle {
      fill: var(--main-black-18);
    }
  }

  .wrapper {
    width: 100%;

    svg {
      margin-left: -0.375rem;
      margin-top: -1rem;
    }

    .note {
      position: absolute;
      right: 0.5rem;
      top: 1.5rem;
      display: flex;

      svg {
        fill: var(--main-black-40);
      }
    }
  }

  .padding {
    padding: 0.25rem 0.5rem;
  }
}

.full {
  width: 100%;
}