.qr-code {
  display: flex;
  width: 100%;
  height: 100%;

  canvas {
    border-radius: 1.5rem;
    background: var(--main-black);
    padding: 12px;
  }
}