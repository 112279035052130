@import "src/app/styles/fonts";

.small-card-info {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: var(--g-800);
  border-radius: 0.75rem;
  padding: 1rem;
  gap: 1rem;
  align-items: center;
  cursor: pointer;

  &__image {
    padding: 11px 21px;
    border: 1px solid var(--g-600);
    border-radius: 0.5rem;

    &.red-border {
      border: 1px solid var(--error-500);
    }

    img {
      width: 30px;
      height: 50px;
    }
  }

  &__text-block {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    &__name {
      @include BodyM-600;

      word-break: break-all;
      color: var(--main-white);
    }

    &__token {
      display: flex;
      flex-direction: row;
      gap: 6px;

      &__id {
        @include BodyS-600;

        color: var(--g-300);
      }

      &__value {
        @include BodyS-600;

        color: var(--b-400);
        cursor: pointer;
      }
    }
  }
}