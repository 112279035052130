@import "src/app/styles/fonts";
@import "src/app/styles/mixinScroll";

.buy-now-success {
  display: flex;
  flex-direction: column;
  width: 21.5rem;
  height: calc(100vh - 5rem);


  & > div:nth-child(3) {
    margin-top: 2rem;
  }

  &__close-btn {
    align-self: flex-end;
  }

  &__transaction {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.375rem;
    padding: 2rem 0 2.5rem;

    &__text {
      @include BodyS-600;

      color: var(--g-300);
    }

    &__value {
      display: flex;
      flex-direction: row;
      gap: 0.25rem;

      a {
        @include BodyS-600;

        color: var(--b-400);
        cursor: pointer;
        text-decoration: none;
      }
    }
  }

  &__share {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    &__title {
      @include BodyS-600;

      color: var(--g-300);
    }

    &__icons {
      display: flex;
      flex-direction: row;
      gap: 1.875rem;

      &__item {
        cursor: pointer;
        fill: var(--main-white);
      }
    }
  }

  &__error-block {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 4.688rem;
    gap: 0.313rem;

    &__text {
      @include BodyM-XS-600;

      color: var(--error-500);
    }
  }

  &__btn {
    margin-top: 2.5rem;
  }
}