@import "src/app/styles/fonts";
@import "src/app/styles/mixinScroll";

.accept-offer-modal-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 37.5rem;
  background-color: var(--g-900);
  border-radius: 1rem;
  padding: 2.5rem 1.5rem;
  justify-content: space-between;
  position: relative;

  &__close-btn {
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
  }

  & > div:nth-child(3) {
    margin-top: 1rem;
  }

  &__button {
    width: 100%;
  }

  &__sold-info,
  &__trnsctn-info {

    &__text,
    &__value {
      @include BodyS-600;
    }

    &__text {
      color: var(--g-300);
      padding-right: 0.375rem;
    }

    &__value {
      color: var(--b-400);
      cursor: pointer;
    }

    &__link {
      padding-left: 0.25rem;
      color: var(--b-400);
      text-decoration: none;
    }
  }

  &__sold-info {
    padding: 0.5rem 0 1rem;
  }

  &__trnsctn-info {
    display: flex;
    align-items: flex-end;
    padding: 3rem 0;
  }
}