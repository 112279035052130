@import "src/app/styles/fonts";
@import "src/app/styles/mixinScroll";

.CollapseFilters {
  color: #fff;
  width: 100%;
  border-bottom: 1px solid #3d3d3d;
}

.open {
  display: flex !important;
  gap: 1rem !important;
}

.items {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 2px;
}

.statusCollapse {
  padding-bottom: 1rem;
  max-height: 15rem;

  overflow: auto;

  &::-webkit-scrollbar-thumb {
    display: none;
  }
}

.titleWrapper {
  cursor: pointer;
  color: var(--main-white);

  @include H5;
}