@import "src/app/styles/fonts";

.print-buttons {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 0.5rem;

  &__buttons {
    display: flex;

    p {
      color: var(--g-200) !important;
    }

    button:hover {
      background: transparent;
    }
  }
}