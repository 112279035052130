@import "src/app/styles/fonts";
@import "src/app/styles/mixinScroll";

.accept-offer-modal-default {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 37.5rem;
  background-color: var(--g-900);
  border-radius: 1rem;
  padding: 1rem 2.5rem 2.5rem;

  &__close-btn {
    align-self: flex-end;
  }

  &__title {
    @include H4-CL;

    color: var(--main-white);
  }

  &__content {
    padding-top: 2rem;
    width: 100%;

    &__owner-errors-block {
      padding: 2rem 0;
    }

    &__from-to {
      display: flex;
      flex-direction: column;
      padding-top: 1rem;

      &__item {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid var(--g-600);

        &__name,
        &__value {
          @include BodyS-600;

          padding: 0.375rem 0;
        }

        &__name {
          color: var(--g-300);
        }

        &__value {
          color: var(--b-400);
          cursor: pointer;
        }
      }
    }

    &__summary {
      padding-top: 1.5rem;
    }

    &__confirming {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1.5rem;
      padding-top: 3.5rem;

      &__description {
        text-align: center;

        p {
          @include BodyM-XS-600;

          color: var(--g-200);
        }
      }
    }

    &__error-block {
      padding-top: 2rem;

      .error-block__content {
        flex-direction: row;
      }
    }
  }
}