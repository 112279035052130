@import "src/app/styles/fonts";

.listing-status {
  min-height: 1.375rem;

  .active {
    padding: 0.125rem 0.375rem;
    width: min-content;
    background: var(--main-white);
    border-radius: 0.375rem;
    color: var(--o-500);
    @include Body-Tiny-800;
  }

  .without-text {
    width: 1rem;
    height: 1rem;
    padding: 0;
    border-radius: 0.25rem;
  }
}