@import "src/app/styles/fonts";
@import "src/app/styles/mixinScroll";

.cancel-offer-success {
  display: flex;
  flex-direction: column;
  background-color: var(--g-900);
  border-radius: 1rem;

  &__close-btn {
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
  }

  &__transaction {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.375rem;
    padding-top: 2rem;

    &__title,
    &__description a {
      @include BodyM-600;
    }

    &__title {
      color: var(--g-300);
    }

    &__description {

      a {
        color: var(--b-400);
        text-decoration: none;
      }
    }
  }

  &__btn {
    align-self: center;
    padding-top: 2rem;
  }
}