@import "src/app/styles/fonts";
@import "src/app/styles/mixinScroll";

.make-offer-modal-default {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 37.5rem;
  background-color: var(--g-900);
  border-radius: 1rem;
  padding: 1.5rem 2.5rem 2.5rem;

  &__close-btn {
    align-self: flex-end;
  }

  &__title {
    @include H4-CL;

    color: var(--main-white);
  }

  &__content {
    padding-top: 2rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__wrapper {
      display: flex;
      flex-direction: column;

      &__input-block {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-top: 2rem;
        gap: 0.375rem;

        &__label {
          @include BodyS-600;

          color: var(--g-100);
        }
      }

      &__alerts {
        display: flex;
        flex-direction: column;
        gap: 0.625rem;

        &__advise {
          display: flex;
          flex-direction: row;
          gap: 0.375rem;

          &__text {
            @include BodyM-XS-600;

            color: var(--g-300);

            &__white {
              color: white;
            }
          }
        }

        &__warning {
          padding-bottom: 2rem;
        }
      }

      &__details {
        display: grid;
        gap: 0.5rem;
        justify-content: space-between;
        grid-template-columns: auto auto auto;
        padding-top: 2rem;

        &__item {

          &__name,
          &__value {
            @include BodyS-600;
          }

          &__name {
            color: var(--g-300);
            padding-right: 0.375rem;
          }

          &__value {
            color: var(--main-white);
            cursor: pointer;
          }
        }
      }
    }

    &__btn {
      padding-top: 2rem;
    }

    &__error-block {
      padding-top: 0.375rem;
      align-self: flex-start;

      .error-block__content {
        flex-direction: row;
      }
    }
  }
}