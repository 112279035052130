@import "src/app/styles/fonts";

.switchWrapper {
  display: flex;
  gap: 0.5rem;
}

.switch {
  position: relative;
  width: 42px;
  height: 22px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.title {
  white-space: nowrap;
  @include BodyS-600;
  color: var(--g-300);
}


.slider {
  position: absolute;
  cursor: pointer;
  inset: 0;
  background-color: var(--g-600);
  transition: .4s;

  &::before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 4px;
    bottom: 5px;
    background-color: var(--main-white);
    transition: .4s;
  }
}

input:checked+.slider {
  background-color: var(--main-green);
}

input:checked+.slider::before {
  transform: translateX(22px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round::before {
  border-radius: 50%;
}