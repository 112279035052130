@import "src/app/styles/fonts";

.transfer-modal {
  display: flex;
  margin: auto;
  width: 35.75rem;
  height: fit-content;

  &__main-modal {
    color: var(--main-white);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 35.75rem;
    background: var(--g-900);
    padding: 1.5rem 2.5rem 2.5rem;
    border-radius: 1.125rem;
    gap: 0;

    &__close {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      margin-right: -2.5rem;
    }

    &__title {
      @include H4-CL;
    }

    &__card {
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 1rem;
      background-color: var(--g-800);
      padding: 1rem;
      border-radius: 0.5rem;
      margin-top: 2rem;

      &__image {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 4.5rem;
        height: 4.5rem;
        background-color: var(--g-700);
        border-radius: 0.5rem;
      }

      &__info {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        justify-content: space-around;
        width: 100%;

        &__name {
          @include BodyM-600;

          cursor: pointer;
        }

        &__bottom {
          display: flex;
          justify-content: space-between;

          &__token-id {
            display: flex;
            gap: 0.375rem;

            @include BodyS-600;

            &__title {
              color: var(--g-300);
            }

            &__value {
              text-decoration: none;
              color: var(--b-400) !important;
            }
          }

          &__price {
            @include BodyS-600;
          }
        }
      }
    }

    &__method {
      display: flex;
      align-self: flex-start;
      gap: 0.5rem;
      margin-top: 2.5rem;
      text-transform: none;

      button {
        text-transform: none;
      }
    }

    &__input {
      display: flex;
      flex-direction: column;
      gap: 0.375rem;
      margin-top: 1.5rem;
      width: 100%;

      &__title {
        @include BodyS-600;
      }

      &__close {

        svg {
          width: 0.75rem;
          height: 0.75rem;

          path {
            fill: var(--g-200);
          }
        }
      }

      &.error {

        *fieldset {
          border: 1px solid var(--error-500) !important;
        }
      }

      &__error {
        display: flex;
        gap: 0.25rem;

        p {
          color: var(--error-500);

          @include BodyM-XS-600;
        }
      }
    }

    &__confirm {
      width: 100%;
      margin-top: 2.5rem;
    }

    &__loader {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 1.5rem;
      color: var(--g-200);

      @include BodyM-XS-600;

      margin: 2.5rem 0;
    }

    &__completed {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__title {
        margin-top: 1rem;

        @include H4-CL;

        color: white;
      }

      &__description {
        display: flex;
        align-items: center;
        gap: 0.375rem;

        @include BodyS-600;

        color: var(--g-300);

        &__link {
          display: flex;
          gap: 0.5rem;
          align-items: center;

          &__value {
            color: var(--b-400) !important;
          }
        }

        svg {
          cursor: pointer;
        }
      }
    }

    &__transaction {
      margin: 3rem 0;

      &__content {
        display: flex;
        justify-content: center;
        gap: 0.375rem;
        color: var(--g-300);

        @include BodyS-600;

        a {
          display: flex;
          gap: 0.375rem;
          align-items: center;
          color: var(--b-400) !important;
          text-decoration: none;
        }

        svg {
          cursor: pointer;
        }
      }
    }

    &__cards {
      width: 100%;
    }

    &__invitation {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-top: 2.5rem;

      &__text {
        @include BodyM-600;

        color: var(--main-white);
      }

      &__buttons {
        display: flex;
        gap: 1rem;
      }
    }
  }
}

.wallet-adapter-modal {
  z-index: 10000000;
}