.Flex {
  display: flex;
}

.justifyStart {
  justify-content: flex-start;
}

.justifyCenter {
  justify-content: center;
}

.justifyEnd {
  justify-content: flex-end;
}

.justifyBetween {
  justify-content: space-between;
}

.justifyUnset {
  justify-content: unset;
}

.alignStart {
  align-items: flex-start;
}

.alignCenter {
  align-items: center;
}

.alignEnd {
  align-items: flex-end;
}

.alignStretch {
  align-items: stretch;
}

.directionRow {
  flex-direction: row;
}

.directionColumn {
  flex-direction: column;
}

.max {
  width: 100%;
}

.wrap {
  flex-wrap: wrap;
}

.nowrap {
  flex-wrap: nowrap;
}

.wrap-reverse {
  flex-wrap: wrap-reverse;
}