@import "src/app/styles/fonts";

.tooltip,
.hover {
  position: relative;

  .tooltip-text,
  .active {
    opacity: 1;
    transition: opacity 0.3s;
    visibility: hidden;
    position: absolute;
    border-radius: 0.375rem;
    left: 50%;
    transform: translateX(-50%);
    padding: 0.25rem;
    background-color: var(--g-100);
    z-index: 100;
    min-width: 4rem;

    &::before {
      content: " ";
      left: 50%;
      border: solid transparent;
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-width: 6px;
      margin-left: calc(6px * -1);
    }

    p {
      @include BodyM-XS-600;
      color: var(--g-900);
      text-align: center;
      -webkit-line-clamp: initial !important;
    }
  }

  .active {
    visibility: visible;
  }
}

.hover:hover {

  .tooltip-text {
    visibility: visible;
  }
}

.top {
  bottom: calc(100% + 8px) !important;

  &::before {
    top: 100% !important;
    border-top-color: var(--g-100) !important;
  }
}

.right {
  left: calc(100% + 8px) !important;
  top: 50% !important;
  transform: translateX(0) translateY(-50%) !important;

  &::before {
    left: calc(6px * -0.9) !important;
    top: 50% !important;
    transform: translateX(0) translateY(-50%) !important;
    border-right-color: var(--g-100) !important;
  }
}

.bottom {
  top: calc(100% + 8px) !important;

  &::before {
    bottom: 100% !important;
    border-bottom-color: var(--g-100) !important;
  }
}

.left {
  left: auto !important;
  right: calc(100% + 8px) !important;
  top: 50% !important;
  transform: translateX(0) translateY(-50%) !important;

  &::before {
    left: auto !important;
    right: calc(6px * -1.9) !important;
    top: 50% !important;
    transform: translateX(0) translateY(-50%) !important;
    border-left-color: var(--g-100) !important;
  }
}