@import "src/app/styles/fonts";

.success-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  &__text {
    color: var(--main-white);
    text-align: center;

    &.clash {
      @include H4-CL;
    }

    &.ordinary {
      @include H3;
    }
  }
}