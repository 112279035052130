@import "src/app/styles/fonts";
@import "src/app/styles/mixinScroll";

.SealedBoxUpdateListingSuccess {
  display: flex;
  flex-direction: column;
  width: 37.5rem;
  gap: 2.8125rem;
  background-color: var(--g-900);
  border-radius: 1rem;
  padding: 2.5rem;
  position: relative;
}

.closeBtn {
  align-self: flex-end;
}

.askingPriceText {
  @include BodyM-600;
  background-color: var(--g-300);
  color: var(--g-300);
}

.askingPriceCurrencyValue {
  @include BodyM-600;
  color: var(--main-white);
}

.transactionTitle {
  @include BodyM-600;
  background-color: var(--g-300);
  color: var(--g-300);
}

.transactionDescription a {
  @include BodyM-600;
  color: var(--b-400);
  text-decoration: none;
}

.button {
  width: 100%;
}