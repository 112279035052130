@import "src/app/styles/fonts";

.button {
  cursor: pointer;
  letter-spacing: 0.02em;
  border-radius: 6.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  height: fit-content;
  white-space: nowrap;
}

.fullWidth {
  width: 100%;
}

.left {
  flex-direction: unset;
}

.right {
  flex-direction: row-reverse;
}

.large {
  padding: 1rem 2.5rem;
  min-width: 12rem;
  @include B-Large;
}

.large-alternative {
  padding: 1rem 2.5rem;
}

.medium {
  padding: 0.75rem 2.5rem;
  min-width: 9rem;
  @include B-Medium;
}

.medium-alternative {
  @include W-BTN-MEDIUM;
  padding: 0.625rem 1.875rem;
  border-radius: 12.5rem;
}

.small {
  padding: 0.5rem 1rem;
  min-width: 5.5rem;
  @include B-Small;
}

.small-alternative {
  padding: 0.5rem 1.25rem;
}

.clear {
  padding: 0;
  border: none;
  background: none;
  outline: none;
}

.active {
  color: var(--main-white) !important;
  fill: var(--main-white) !important;

  &:hover {
    background: transparent;
  }
}

.primary {
  background: var(--main-white);
  color: var(--main-black);
  fill: var(--main-black);
  border: none;

  &:hover {
    background: var(--g-50);
  }

  &:disabled {
    background: var(--g-700) !important;
    color: var(--g-500) !important;
    fill: var(--g-500) !important;
  }

  &:active {
    background: var(--g-100);
  }
}

.primary-alternative {
  background: var(--w-g-0);
  color: var(--w-g-1000);
  fill: var(--w-g-1000);
  border: none;

  &:hover {
    background: var(--g-200);
  }

  &:disabled {
    background: var(--g-800) !important;
    color: var(--g-500) !important;
    fill: var(--g-500) !important;
  }

  &:active {
    background: var(--g-500);
  }
}

.secondary {
  background: transparent;
  color: var(--main-white);
  fill: var(--main-white);
  border: 1px solid var(--g-500);

  &:hover {
    color: var(--g-50);
    background: var(--g-900);
    fill: var(--g-50);
  }

  &:disabled {
    background: transparent !important;
    color: var(--g-600) !important;
    fill: var(--g-600) !important;
  }

  &:active {
    background: var(--g-500);
    color: var(--g-50);
    fill: var(--g-50);
  }
}

.secondary-alternative {
  background: transparent;
  color: var(--w-g-0);
  fill: var(--w-g-0);
  border: 1px solid var(--g-300);

  &:hover {
    background: var(--g-800);
    border-color: 1px solid var(--g-900);
  }

  &:disabled {
    background: transparent !important;
    border-color: 1px solid var(--g-900) !important;
  }

  &:active {
    background: var(--g-300);
    border: none;
  }
}

.ghost {
  background: transparent;
  color: var(--g-200);
  fill: var(--g-200);
  border: none;

  &:hover {
    color: var(--main-white);
    fill: var(--main-white);
  }

  &:disabled {
    background: transparent !important;
    color: var(--g-700) !important;
    fill: var(--g-700) !important;
  }

  &:active {
    color: var(--g-500);
    fill: var(--g-500);
  }
}

.black {
  background: var(--main-black);
  color: var(--main-white);
  fill: var(--main-white);

  &:hover {
    background: var(--g-700);
  }

  &:disabled {
    background: var(--g-100) !important;
    color: var(--g-200) !important;
    fill: var(--g-200) !important;
  }

  &:active {
    background: var(--g-900);
    color: var(--g-200);
    fill: var(--g-200);
  }
}

.black-alternative {
  background: var(--w-g-1000);
  color: var(--w-g-0);
  fill: var(--w-g-0);

  &:hover {
    background: var(--g-800);
  }

  &:disabled {
    background: var(--g-800) !important;
    color: var(--g-500) !important;
    fill: var(--g-500) !important;
  }

  &:active {
    background: var(--w-g-900);
    color: var(--g-300);
    fill: var(--g-300);
  }
}

.white {
  background: white;
  color: var(--main-black);
  fill: var(--main-black);
  border: none;

  &:hover {
    background: var(--g-100);
  }

  &:disabled {
    background: var(--g-700) !important;
    color: #575454 !important;
  }

  &:active {
    background: var(--g-200);
  }
}