@import "src/app/styles/fonts";
@import "src/app/styles/mixinScroll";

.edit-track-modal {
  margin: auto;
  height: 17rem;

  &__main-modal {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 0.5rem;
    width: 40rem;
    background: var(--g-900);
    padding: 2rem 2rem 2.5rem;
    border-radius: 1rem;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__close {
        display: flex;
        min-height: 4rem;
      }
    }

    &__header>p {
      @include H2;

      text-transform: uppercase;
      color: var(--main-white);
    }

    &__main-content {
      color: var(--main-white);
      display: flex;
      justify-content: space-between;
      height: 100%;
      flex-direction: column;
      gap: 2rem;

      @include BodyM-400;

      &__input {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;

        h3 {
          @include BodyS-600;

          letter-spacing: 0.05em;
          text-transform: uppercase;
        }
      }

      &__buttons {
        display: flex;
        gap: 1rem;
        padding: 0 0.5rem;
      }
    }
  }

  &__main-modal:focus-visible {
    outline: none;
  }
}

.cancel-modal:focus-visible {
  outline: none;
}