@import "src/app/styles/fonts";
@import "src/app/styles/mixinScroll";

.SealedBoxListingDefault {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--g-900);
  border-radius: 1rem;
  padding: 1rem;
}

.closeBtn {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.cardPreview {
  padding-top: 2rem;
}

.titleText {
  text-align: center;
  color: var(--main-white);
}

.buttonsTitle {
  padding: 1.5rem 0 0.375rem;
}

.inputBlock {
  width: 100%;
  padding-top: 1.5rem;
}

.ownerError {
  padding: 2rem 0;
}

.receiveInfo {
  padding-top: 0.5rem;
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.receiveInfoText {
  @include BodyM-XS-600;
  color: var(--g-300);

  span {
    color: white;
  }
}

.errorBlock {
  align-self: flex-start;
  padding: 0.375rem 0 2rem;
}

.buttonBlock {
  width: 100%;
  flex-grow: 1;
  padding-top: 1.5rem;
}

.bottomBlock {
  flex-grow: 1;
}

.loadingTx {
  padding-top: 5rem;
}