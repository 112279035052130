@import "src/app/styles/fonts";


.loading {
  position: absolute;
  top: -12px;
  right: -11px;
  background: rgb(18 18 18 / 29%);
  min-width: 18.5rem;
  min-height: 32.3125rem;
  border-radius: 1rem !important;
}

.spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-top: 7.5rem;
}


.ConfirmSent {
  width: 100%;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7rem;
  height: 7rem;
  background: rgb(18 18 18 / 44%);
  border-radius: 1rem;
}

.amount {
  @include H4-CL;

}

.sendInfo {
  padding: 0.75rem 1rem 0.5rem;
  min-height: 6.5rem;
  border: 1px solid var(--g-600);
  border-radius: 1rem;

}

.name {
  @include BodyS-400;
  color: var(--g-300);
}

.value {
  @include BodyS-400;
  color: var(--main-white);
}