.page {
  display: flex;
  flex-direction: column;
  min-width: 1432px;
  min-height: calc(100vh - 100px - 88px);
}

.content {
  display: flex;
  flex: auto;
  align-items: center;
  gap: 0.5rem;
  max-width: 1432px;
  width: 100%;
}

.trigger {
  height: 20px;
  margin: 10px;
}

.left {
  justify-content: flex-start !important;
}

.center {
  justify-content: center;
  align-items: center;
}

.right {
  justify-content: flex-end;
}

.top {
  align-items: flex-start;
}

.bottom {
  align-items: flex-end;
}

@media screen and (max-width: 480px) {
  .page {
    min-width: unset;
    width: 100vw;
  }
}