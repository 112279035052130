@import "src/app/styles/fonts";

.SentMoney {
  width: 100%;
}

.icons {
  cursor: pointer;
}

.title {
  @include H6-CL;

}

.next {
  transform: rotate(180deg);
}

.typeWallet {
  cursor: pointer;
  padding: 0.75rem 0.625rem;
  width: 17rem;
  height: 3.875rem;
  border: 1px solid var(--g-600);
  border-radius: 12px;

  &:hover {
    background: var(--g-600);
  }
}

.value{
  @include BodyM-XS-600;

  color: var(--g-200)

}