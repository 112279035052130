@import "src/app/styles/fonts";
@import "src/app/styles/mixinScroll";

.buy-now-default {
  display: flex;
  flex-direction: column;
  width: 21.5rem;
  height: calc(100vh - 5rem);
  gap: 1.5rem;

  &__title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: -0.25rem;

    &__text {
      @include H4-CL;

      color: var(--main-white);
    }
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    &__details {
      display: flex;
      flex-direction: column;
      padding: 0 0.5rem;
      max-height: 5.625rem;
      overflow-y: scroll;

      &>div:not(:last-child) {
        border-bottom: 1px solid var(--g-600);
      }

      &>div:first-child {
        padding-top: 0;
      }

      &__text-block {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0.25rem 0;

        &__name {
          @include BodyS-600;

          color: var(--g-300);
        }

        &__value {
          @include BodyS-600;

          color: var(--main-white);

          &.link {
            color: var(--b-400);
            cursor: pointer;
          }
        }
      }
    }

    &__amount {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
      border: 1px solid var(--g-600);
      border-radius: 1rem;

      &__title {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        @include BodyM-600;

        color: var(--main-white);

        svg {

          path {
            fill: var(--main-white);
          }
        }
      }

      &__summary {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.75rem;

        &__value {
          @include BodyM-600;

          color: var(--main-white);
        }

        &__currency {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0.25rem 0.5rem;
          border-radius: 0.5rem;
          background-color: var(--main-black);
          gap: 0.25rem;

          &__text {
            font-family: Manrope;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: var(--main-white);
          }
        }
      }
    }

    &__wallet {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: white;
      background-color: var(--main-black);
      border-radius: 1rem;
      padding: 0.75rem 1.25rem 0.75rem 0.75rem;

      &__left {
        display: flex;
        gap: 0.75rem;

        svg {
          background:
            linear-gradient(150.69deg,
              #2be4ca 13.99%,
              #1bcbb8 20.5%,
              #4d78e4 53.32%,
              #8b61fc 75.3%,
              #ad8fff 89.01%);
          border-radius: 100px;
        }

        &__content {

          &__address {
            @include BodyM-XS-600;
          }

          &__solana {
            @include BodyM-XS-600;

            color: var(--g-300);
          }
        }
      }

      &__right {
        cursor: pointer;
        padding-left: 0.35rem;
      }
    }

    &__pay-method {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;

      &__title {
        @include BodyM-600;
        color: var(--g-100)
      }

      &__buttons {
        display: flex;
        flex-direction: row;
        gap: 2rem;

        &__item {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.75rem;

        &__info {
        display: flex;
        align-items: center;
        gap: 0.25rem;

          span {
            @include BodyS-600;
            color: var(--g-100)
            }
        }
        }
      }
    }
  }
}

button.crossmint-btn {
  background: white;
  padding: 0.75rem 2.5rem;
  border-radius: 6.25rem;
  width: 100%;
  height: fit-content;
  box-shadow: none;

  &:hover {
    opacity: 1 !important;
  }
}

button.crossmint-btn span {
  display: none;
}

button.crossmint-btn::after {
  content: "Buy with credit card";
  @include B-Medium;
  min-width: 9rem;
  text-transform: uppercase;
  color: var(--main-black);
  letter-spacing: 0.02em;
}