.skeleton {
  width: 100%;
  height: 50px;
  position: relative;
  background: rgb(29 29 29 / 5%);

  overflow: hidden;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 80%;
    background: linear-gradient(270deg, rgb(29 29 29 / 20%) 0%, rgb(29 29 29 / 16%) 50.87%, rgb(29 29 29 / 0%) 100%);
    animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
}

@keyframes load {

  from {
    left: -150px;
  }

  to {
    left: 100%;
  }
}